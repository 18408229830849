import { atom } from "jotai";
import { checkForV3Migration } from "../../util/checkForV3Migration";
import { loadOrg, loadTeam } from "./base";
import { protectedApiCall } from "../../api/network";
import { setCanCancelMigrationAlert } from "./migration/v3MigrationAtom";

interface DiscoverySessionContext {
  groupID: string;
  orgID: string;
}

interface DiscoverySessionUser {
  userID: string;
  username: string;
  email: string;
}

export enum DiscoverySessionState {
  loading,
  loading_org,
  loading_group,
  ready
}

export interface DiscoverySession {
  sessionID: string;
  apiToken: string;
  legacyApiToken: string;
  user: DiscoverySessionUser | undefined;
  session: DiscoverySessionContext | any;
  state: DiscoverySessionState;
}

export const discoverySession: DiscoverySession = {
  sessionID: "",
  apiToken: "",
  legacyApiToken: "",
  user: undefined,
  session: {},
  state: DiscoverySessionState.loading
};

export const discoverySessionAtom = atom(discoverySession);

export const setSessionByOrgID = (
  set,
  session,
  orgID: string,
  setBase,
  setCategoryState,
  setV3MigrationAtomState: Function
) => {
  protectedApiCall(
    session,
    "/session/v1",
    null,
    {
      params: {
        orgID
      }
    },
    "put"
  ).then((r: any) => {
    set((session) => {
      let buffer = {
        ...session,
        state: DiscoverySessionState.loading_org
      };

      try {
        buffer.session.orgID = orgID;
      } catch (e) {}

      try {
        //@ts-ignore
        buffer.session.groupID = r.session.groupID;
      } catch (e) {}

      checkForV3Migration(
        r,
        buffer.apiToken,
        setV3MigrationAtomState,
        () => {
          loadOrg(setBase, setCategoryState, orgID, buffer, () => {
            buffer.state = DiscoverySessionState.ready;

            persistSessionChange(buffer);
          });
        },
        () => {
          setCanCancelMigrationAlert(setV3MigrationAtomState, true);
        }
      );

      return buffer;
    });
  });
};

interface SetSessionByGroupIDParams {
  setBase: Function;
  setCategoryState: Function;
  set: Function;
  existing: DiscoverySession;
  groupID: string;
  statesWithPreviousValuesToHold: any;
  setV3MigrationAtomState: Function;
}

export const setSessionByGroupID = (params: SetSessionByGroupIDParams) => {
  const {
    setBase,
    setCategoryState,
    set,
    existing,
    groupID,
    statesWithPreviousValuesToHold,
    setV3MigrationAtomState
  } = params;

  set((session) => {
    let buffer = {
      ...session,
      state: DiscoverySessionState.loading_org
    };

    try {
      buffer.session.groupID = groupID;
    } catch (e) {}

    return buffer;
  });

  protectedApiCall(
    existing,
    "/session/v1",
    null,
    {
      params: {
        groupID
      }
    },
    "put"
  ).then((r: any) => {
    set((session) => {
      let buffer = {
        ...session,
        ...r.data,
        session: {
          groupID: groupID
        },
        state: DiscoverySessionState.loading_group
      };

      try {
        buffer.session.groupID = groupID;
      } catch (e) {}

      persistSessionChange(buffer);

      checkForV3Migration(
        r,
        buffer.apiToken,
        setV3MigrationAtomState,
        () => {
          const persistSessionCallback = () => {
            set((session) => {
              let buffer = {
                ...session,
                state: DiscoverySessionState.ready
              };

              try {
                buffer.session.groupID = groupID;
              } catch (e) {}

              persistSessionChange(buffer);

              return buffer;
            });
          };

          loadTeam(
            setBase,
            setCategoryState,
            groupID,
            buffer.apiToken,
            existing,
            persistSessionCallback,
            statesWithPreviousValuesToHold
          );
        },
        () => {
          setCanCancelMigrationAlert(setV3MigrationAtomState, true);
        }
      );

      return buffer;
    });
  });
};

export async function persistSessionChange(base) {
  try {
    const { sessionID } = base;

    localStorage.setItem(`sessionPack-${sessionID}`, JSON.stringify(base));
  } catch (e) {
    console.error(e);
  }
}
