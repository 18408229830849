import Priority_High from '../icons/priority_high.svg'
import Priority_Medium from '../icons/priority_medium.svg'
import Priority_Low from '../icons/priority_low.svg'
import Priority_Urgent from '../icons/priority_urgent.svg'
import Information_details from '../icons/information_details.svg'

export const COMPONENT_GLOBAL_CONFIG = {
	CANVAS_ENTRY: {
		HOVER_OVER_TOOLBAR: {
			ENTER_NEXT_DELAY: 300,
			ENTER_DELAY: 300,
		},
	},
	TOOLTIP: {
		ENTER_NEXT_DELAY: 300,
		ENTER_DELAY: 300,
	},
	MAX_INSIGHT_RATING_STAR_COUNT: 5
};

export const ROLES = {
	TEAM_MEMBER: "teammember",
	COHORT_ADMINISTRATOR: "cohortadministrator",
	INSTRUCTOR: "instructor",
	MENTOR: "mentor",
};

export const APP_RETRY_TIMER = {
	INTERVAL: 60 * 1000,
	RETRY_COUNT: Infinity,
}

export const ACTIVITY_FEED_TYPES = {
    FINDING: "Finding",
    HYPOTHESIS: "Hypothesis",
    HYPOTHESIS_STATE_CHANGE: "HypothesisStateChange",
    INTERVIEW: "Interview",
    HYPOTHESIS_LINK: "HypothesisLink",
    FINDING_LINK: "FindingLink",
    ENTRY_CONTENT_CHANGE: "EntryContentChange",
    HYPOTHESIS_BULK_CREATE: "HypothesisBulkCreate",
    HYPOTHESIS_BULK_DELETE: "HypothesisBulkDelete",
};

export const HYPOTHESIS_ACTIVITIES = [
    ACTIVITY_FEED_TYPES.HYPOTHESIS_LINK,
    ACTIVITY_FEED_TYPES.HYPOTHESIS,
    ACTIVITY_FEED_TYPES.ENTRY_CONTENT_CHANGE,
    ACTIVITY_FEED_TYPES.HYPOTHESIS_STATE_CHANGE,
    ACTIVITY_FEED_TYPES.FINDING_LINK,
    ACTIVITY_FEED_TYPES.HYPOTHESIS_BULK_CREATE,
    ACTIVITY_FEED_TYPES.HYPOTHESIS_BULK_DELETE,
];

export const WHITELIST_ACTIVITY_FEED_TYPES = [
    ...HYPOTHESIS_ACTIVITIES,
    ACTIVITY_FEED_TYPES.FINDING,
];

export const KEY_CODES = {
	ENTER: 13,
}

export const ROLE_COLORS = {
	TeamMember: "#2e7d32",
	Mentor: "#1565c0",
	Observer: "#565656",
	Instructor: "#ffee58",
	CohortAdministrator: "#c62828",
	Administrator: "#c62828",
	SystemAdministrator: "#565656",
};

export const DEFAULT_INSIGHT_TAGS_BY_USER_ROLE = {
	TEAM_MEMBER: "General Insight",
	INSTRUCTOR: "Feedback",
};

export const APP_NOTIFICATION_COMPONENT_STATIC_TEXTS = {
	ICON_BUTTON: {
		TOOLTIP: "Show All Notifications",
	},
	POPOVER: {
		TITLE: "Notifications",
	},
	MARK_AS_READ_BUTTON: {
		TOOLTIP: "Mark as read",
	},
	MARK_AS_UNREAD_BUTTON: {
		TOOLTIP: "Mark as unread",
	},
	MARK_ALL_AS_READ_BUTTON: {
		LABEL: "Mark all as read",
	},
	SHOW_ONLY_UNREAD_SWITCH: {
		LABEL: "Show unread only",
	},
};

export const PUBNUB_MESSAGE_TYPE = {
	COMMENT: "comment",
	NOTIFICATION: "notification",
	CHAT: "chat",
	CHAT_UPDATE: "chat update",
	CHAT_DELETE: "chat delete",
	TEMPLATE: "template",
	AI_TEMPLATE: "ai:template",
	AI_CANVAS: "ai:canvas",
	AI_INSIGHT: "ai:insight",
	DEEPGRAM: "deepgram",
	AUDIO_ENCODING: "audio_encoding",
	ENTRY: 'entry',
	INSIGHT: 'insight',
	ACTIVITY: 'activity',
	ASSEMBLY: 'assemblies',
	MEDIA_ASSEMBLY: "media-assemblies",
	INTERVIEW: "interview",
	EXPORT_TEAM_INTERVIEW: "export_team_interview",
    CANVAS_IMPORT: "canvas_import"
};

export const NOTIFICATION_TYPES = {
	INSIGHT: "insight",
	COMMENT: "bmc-comment",
	EXPORT: "export",
	EXPORT_TEAM_INTERVIEW: "export_team_interview",
	AI_INSIGHT: PUBNUB_MESSAGE_TYPE.AI_INSIGHT,
};

export const NOTIFICATION_UNREAD_SWITCH_VALUES = {
	ALL: "All",
	UNREAD: "Unread",
};

export const CALENDAR_SWITCH_VALUES = {
	CALENDAR: "CALENDAR",
	MEETING_LIST: "MEETING LIST",
};

export const BMC_GEN_SWITCH_VALUES = {
	CANVAS: "Canvas",
	ACTIVITY: "Activity",
};

export const INTERVIEW_NAVIGATION_TABS = {
	PUBLISHED: "published",
	DRAFT: "draft",
}

export const APP_ELEMENT_DIMENSIONS = {
	CANVAS_TOOLBAR: {
		HEIGHT: "48px",
	},
	CHAT_PANEL: {
		WIDTH: "400px",
	},
	ANALYTICS: {
		COHORT_STATS: {
			OVERVIEW_PANEL: {
				WIDTH: "225px",
			},
		},
	},
	SCORING: {
		STATS: {
			HEIGHT: "260px",
		},
		OVERVIEW_STAT: {
			WIDTH: "256px",
		}
	}
};

export const UPLOAD_STATUS_MENU_ITEM_TEXTS = {
	RETRY_BUTTON: {
		TOOLTIP: "Retry",
	},
	CANCEL_BUTTON: {
		TOOLTIP: "Cancel Upload",
	},
};

export const TEMPLATE_EDITOR_MODAL_TEXTS = {
	MODAL_HEADER: {
		CLOSE_BUTTON: {
			EMPTY_TEMPLATE_NAME: {
				TOOLTIP: "Please, provide a template title",
			},
			NORMAL: {
				TOOLTIP: "Close",
			},
		},
		BACK_BUTTON: {
			NORMAL: {
				TOOLTIP: "Return to Template List",
			},
		},
	},
};

export const LOADING_DATA_TEXT = "Loading data, please wait...";

export const NOTIFICATION_OVERVIEW_TEXTS = {
	SEGMENT_SECTION_TITLE: "Segment Name",
	COMMENT_SECTION_TITLE: "Comment",
	INTERVIEW_SECTION_TITLE: "Interview",
	QUOTE_SECTION_TITLE: "Quote",
};

export const TEAM_DETAILS_POPOVER_CHART_TEXTS = {
	INTERVIEW_INSIGHT_CHART: {
		TITLE: "Interview and Insight Metrics",
	},
	INTERVIEW_INSIGHT_CHART_BY_MEMBER: {
		TITLE: "Interviews and Insights By Team Member",
	},
	HYPOTHESIS_METRICS_CHART: {
		TITLE: "Hypothesis Metrics",
	},
};

export const TEMPLATE_CONTENT_CHANGE_INDICATOR_SHOW_TIME = 3000; // in miliseconds

export const NOT_AVAILABLE_TEXT = "Not Available";

export const PROD_URLS = [
	"innovationwithin.com",
	"app.innovationwithin.services",
	"v3preview.innovationwithin.services",
];

export const DEFAULT_SCORING_RATING = 3;

export const STAR_RATINGS_DISTRIBUTION = {
	Problem: 1,
	"Cause for Concern": 2,
	"Acceptable/Okay": 3,
	Good: 4,
	"Very Good/Excellent": 5,
};

export const ALLOWED_PUBUNB_MESSAGE_TYPE = [
	PUBNUB_MESSAGE_TYPE.TEMPLATE,
	PUBNUB_MESSAGE_TYPE.AI_CANVAS,
	PUBNUB_MESSAGE_TYPE.AI_TEMPLATE,
	PUBNUB_MESSAGE_TYPE.AI_INSIGHT,
	PUBNUB_MESSAGE_TYPE.DEEPGRAM,
	PUBNUB_MESSAGE_TYPE.AUDIO_ENCODING,
	PUBNUB_MESSAGE_TYPE.CHAT,
	PUBNUB_MESSAGE_TYPE.CHAT_UPDATE,
	PUBNUB_MESSAGE_TYPE.CHAT_DELETE,
	PUBNUB_MESSAGE_TYPE.COMMENT,
	PUBNUB_MESSAGE_TYPE.NOTIFICATION,
	PUBNUB_MESSAGE_TYPE.ENTRY,
	PUBNUB_MESSAGE_TYPE.INSIGHT,
	PUBNUB_MESSAGE_TYPE.ACTIVITY,
	PUBNUB_MESSAGE_TYPE.ASSEMBLY,
	PUBNUB_MESSAGE_TYPE.MEDIA_ASSEMBLY,
];

export const PUBNUB_MESSAGE_ACTION = {
    CREATE: "create",
    UPDATE: "update",
    DELETE: "delete",
    GENERATE: "generate",
    BULK_CREATE: "bulk_create",
    BULK_DELETE: "bulk_delete",
};

export const INTERVIEW_PAGE_WIDTH_LIMITS = {
	ORG_TREE: 1700,
	INTERVIEW_LIST: 1360,
}

export const ARTIFACT_TYPES = {
	INTERVIEW_NOTE: "interview_note",
	TRANSCRIPT: "transcript",
	TEMPLATE: "template",
	CANVAS_NOTE: "canvas_note",
};

export const ARTIFACT_TYPE_INTERVIEW_TAB_MAP = {
	[ARTIFACT_TYPES.TRANSCRIPT]: "transcription",
	[ARTIFACT_TYPES.INTERVIEW_NOTE]: "notes",
}

export const REMIRROR_BLOT_TYPES = {
	INSIGHT: "insight",
}

export const INSIGHT_ACTIONS = {
	CREATE: "create",
	DELETE: "delete",
	BULK_CREATE: "bulk_create",
}

export const INTERVIEW_ASSEMBLY_TYPES = {
  VIDEO: "video",
  AUDIO: "audio",
};

export const REALTIME_TEMPLATE_DELETE_MESSAGE = "Template you are editing is deleted.";

export const AI_GENERATION_PROGRESS = {
	NOT_STARTED: "not_started",
	IN_PROGRESS: "in_progress",
	DONE: "done",
}

export const AI_GENERATION_COMPLETION_STATUS = {
    NO_STATUS: "no_status",
    IN_PROGRESS: "in_progress",
    SUCCESSFULL: "successfull",
    FAILED: "failed",
};

export const EDITOR_MAP = {
	QUILL: "react-quill",
	MATERIAL: "material",
};

export const KEY_PARTNERS = "Key Partners";
export const KEY_ACTIVITIES = "Key Activities";
export const KEY_RESOURCES = "Key Resources";
export const VALUE_PROPOSITIONS = "Value Propositions";
export const CUSTOMER_RELATIONSHIPS = "Customer Relationships";
export const CHANNELS = "Channels";
export const CUSTOMER_SEGMENTS = "Customer Segments";
export const COST_STRUCTURE = "Cost Structure";
export const REVENUE_STREAMS = "Revenue Streams";

export const PROBLEM = "Problem";
export const SOLUTION = "Solution";
export const KEY_METRICS = "Key Metrics";
export const UNFAIR_ADVANTAGE = "Unfair Advantage";
export const EXISTING_ALTERNATIVES = "Existing Alternative";
export const HIGH_LEVEL_CONCEPT = "High-Level Concept";
export const EARLY_ADOPTERS = "Early Adopters";
export const CUSTOMER_SEGMENTS_STAKHOLDERS ="Customer Segments and Stakholders";
export const UNIQUE_VALUE_PROPOSITIONS="Unique Value Propositions";
export const PARTNERS="Partners";
export const REVENUE_STRUCTURES="Revenue Structures";
export const DISTRIBUTION_CHANNELS="Distribution Channels";

export const KEY_PARTNERS_ABBREVIATION = "KP";
export const KEY_ACTIVITIES_ABBREVIATION = "KA";
export const KEY_RESOURCES_ABBREVIATION = "KR";
export const VALUE_PROPOSITIONS_ABBREVIATION = "VP";
export const CUSTOMER_RELATIONSHIPS_ABBREVIATION = "CR";
export const CHANNELS_ABBREVIATION = "CH";
export const CUSTOMER_SEGMENTS_ABBREVIATION = "CS";
export const COST_STRUCTURE_ABBREVIATION = "CS";
export const REVENUE_STREAMS_ABBREVIATION = "RS";

export const PROBLEM_ABBREVIATION = "PM";
export const SOLUTION_ABBREVIATION = "SL";
export const KEY_METRICS_ABBREVIATION = "KM";
export const UNFAIR_ADVANTAGE_ABBREVIATION = "UA";
export const EXISTING_ALTERNATIVES_ABBREVIATION = "EA";
export const HIGH_LEVEL_CONCEPT_ABBREVIATION = "HC";
export const EARLY_ADOPTERS_ABBREVIATION = "EA";
export const CUSTOMER_SEGMENTS_STAKHOLDERS_ABBREVIATION="CS"; 
export const UNIQUE_VALUE_PROPOSITIONS_ABBREVIATION="UP"; 
export const PARTNERS_ABBREVIATION="PR";
export const REVENUE_STRUCTURES_ABBREVIATION="RS";
export const DISTRIBUTION_CHANNELS_ABBREVIATION="DC";

export const CATEGORIES_BY_LEGACY_SORT_KEY = {
    BMC: {
        1: {
            name: KEY_PARTNERS,
            abbreviation: KEY_PARTNERS_ABBREVIATION,
        },
        2: {
            name: KEY_ACTIVITIES,
            abbreviation: KEY_ACTIVITIES_ABBREVIATION,
        },
        3: {
            name: KEY_RESOURCES,
            abbreviation: KEY_RESOURCES_ABBREVIATION,
        },
        4: {
            name: VALUE_PROPOSITIONS,
            abbreviation: VALUE_PROPOSITIONS_ABBREVIATION,
        },
        5: {
            name: CUSTOMER_RELATIONSHIPS,
            abbreviation: CUSTOMER_RELATIONSHIPS_ABBREVIATION,
        },
        6: {
            name: CHANNELS,
            abbreviation: CHANNELS_ABBREVIATION,
        },
        7: {
            name: CUSTOMER_SEGMENTS,
            abbreviation: CUSTOMER_SEGMENTS_ABBREVIATION,
        },
        8: {
            name: COST_STRUCTURE,
            abbreviation: COST_STRUCTURE_ABBREVIATION,
        },
        9: {
            name: REVENUE_STREAMS,
            abbreviation: REVENUE_STREAMS_ABBREVIATION,
        },
    },
    LEAN: {
        1: {
            name: PROBLEM,
            abbreviation: PROBLEM_ABBREVIATION,
        },
        2: {
            name: SOLUTION,
            abbreviation: SOLUTION_ABBREVIATION,
        },
        3: {
            name: KEY_METRICS,
            abbreviation: KEY_METRICS_ABBREVIATION,
        },
        4: {
            name: VALUE_PROPOSITIONS,
            abbreviation: VALUE_PROPOSITIONS_ABBREVIATION,
        },
        5: {
            name: UNFAIR_ADVANTAGE,
            abbreviation: UNFAIR_ADVANTAGE_ABBREVIATION,
        },
        6: {
            name: CHANNELS,
            abbreviation: CHANNELS_ABBREVIATION,
        },
        7: {
            name: CUSTOMER_SEGMENTS,
            abbreviation: CUSTOMER_SEGMENTS_ABBREVIATION,
        },
        8: {
            name: COST_STRUCTURE,
            abbreviation: COST_STRUCTURE_ABBREVIATION,
        },
        9: {
            name: REVENUE_STREAMS,
            abbreviation: REVENUE_STREAMS_ABBREVIATION,
        },
        10: {
            name: EXISTING_ALTERNATIVES,
            abbreviation: EXISTING_ALTERNATIVES_ABBREVIATION,
        },
        // as the prod and ci has different value of "i" in the canvasLayout for "High-Level Concept" and "Early Adopters"
        // a corresponding change is introduced in the backend in "backend\services\linx\src\constants\canvas.mjs" file
        11:
            process.env.NEXT_PUBLIC_NODE_ENV === "ci"
                ? {
                      name: EARLY_ADOPTERS,
                      abbreviation: EARLY_ADOPTERS_ABBREVIATION,
                  }
                : {
                      name: HIGH_LEVEL_CONCEPT,
                      abbreviation: HIGH_LEVEL_CONCEPT_ABBREVIATION,
                  },
        // as the prod and ci has different value of "i" in the canvasLayout for "High-Level Concept" and "Early Adopters"
        // a corresponding change is introduced in the backend in "backend\services\linx\src\constants\canvas.mjs" file
        12:
            process.env.NEXT_PUBLIC_NODE_ENV === "ci"
                ? {
                      name: HIGH_LEVEL_CONCEPT,
                      abbreviation: HIGH_LEVEL_CONCEPT_ABBREVIATION,
                  }
                : {
                      name: EARLY_ADOPTERS,
                      abbreviation: EARLY_ADOPTERS_ABBREVIATION,
                  },
    },
	CUSTOM: {
        1: {
            name: KEY_PARTNERS,
            abbreviation: KEY_PARTNERS_ABBREVIATION,
        },
        2: {
            name: KEY_ACTIVITIES,
            abbreviation: KEY_ACTIVITIES_ABBREVIATION,
			
        },
        3: {
			name: VALUE_PROPOSITIONS,
            abbreviation: VALUE_PROPOSITIONS_ABBREVIATION,
            
        },
        4: {
			name: CUSTOMER_RELATIONSHIPS,
            abbreviation: CUSTOMER_RELATIONSHIPS_ABBREVIATION,
        },
        5: {
			name: CUSTOMER_SEGMENTS,
            abbreviation: CUSTOMER_SEGMENTS_ABBREVIATION,
			
        },
        6: {
            name: COST_STRUCTURE,
            abbreviation: COST_STRUCTURE_ABBREVIATION,
        },
    },
	DTHREE: {
        1: {
            name: PROBLEM,
            abbreviation: PROBLEM_ABBREVIATION,
        },
        2: {
            name: CUSTOMER_SEGMENTS,  
            abbreviation: CUSTOMER_SEGMENTS_ABBREVIATION,
			
        },
        3: {
            name: UNIQUE_VALUE_PROPOSITIONS,
            abbreviation: UNIQUE_VALUE_PROPOSITIONS_ABBREVIATION,
        },
        4: {
            name: SOLUTION,
            abbreviation: SOLUTION_ABBREVIATION,
        },
        5: {
            name: EXISTING_ALTERNATIVES,
            abbreviation: EXISTING_ALTERNATIVES_ABBREVIATION,
			
        },
        6: {
            name: UNFAIR_ADVANTAGE,
            abbreviation: UNFAIR_ADVANTAGE_ABBREVIATION,
        },
        7: {
            name: PARTNERS, 
            abbreviation: PARTNERS_ABBREVIATION, 
        },
        8: {
            name: COST_STRUCTURE,
            abbreviation: COST_STRUCTURE_ABBREVIATION,
        },
        9: {
            name: REVENUE_STRUCTURES,
            abbreviation: REVENUE_STRUCTURES_ABBREVIATION,
        },
        10: {
			name: DISTRIBUTION_CHANNELS,
            abbreviation: DISTRIBUTION_CHANNELS_ABBREVIATION,
        },
    },
};

export const CANVAS_TYPE = {
	LeanCanvas: "LEAN",
    BusinessModelCanvas: "BMC",
    CustomCanvas: "CUSTOM",
    DThreeCanvas: "DTHREE",
};

export const CALENDAR_COMPONENT_STATIC_TEXTS = {
	POPOVER: {
		TITLE: "Calendar",
	},
	ConnectCalendar:{
		INFO_TEXT: "Your upcoming interviews and meetings on your calendar show up here.",
		CONNECT_BUTTON: "Connect Calendar",
	},
	ZoomConnectedMessage:{
		SUCCESS_MESSAGE: "Success! Your Zoom account is now connected.",
		INFO_TEXT: "This setup lets you seamlessly manage recordings and access transcripts right from the app!",
		GOT_IT_BUTTON:"Got it"
	},
	CalendarConnectedMessage:{
		SUCCESS_MESSAGE: "Google Calendar connected successfully!",
		INFO_TEXT: "You can now view your upcoming meetings directly in the app. To unlock more features like automatic meeting transcription, connect your Zoom account.",
		LATER_BUTTON:"Later",
		CONNECT_BUTTON:"Connect Zoom"
	}
}

export const CALENDAR_CONNECT_ERROR = "Your Calendar and Zoom emails don't match. Please reconnect using the same email address."
export const MODIFY_MEETING_ERROR = "You can't modify this meeting's settings here because it was enabled in another team. Please go back to the original team to make changes.";
export const RECURRING_ERROR = "Recurring Zoom interviews not supported. Please schedule individual sessions instead."

export const MATERIAL_CANVAS_NOTE_EDITOR_ALERT_TYPE = {
	DELETE: "delete",
	GENERATE: "generate",
}

export const DASHJS_EVENTS = {
	PLAYBACK_STARTED: "playbackStarted",
	PLAYBACK_PAUSED: "playbackPaused",
	PLAYBACK_ENDED: "playbackEnded",
	PLAYBACK_PLAYING: "playbackPlaying",
	CAN_PLAY: "canPlay",
}

export const MAXIMUM_MEDIA_FILE_SIZE_MB =
    parseInt(process.env.NEXT_PUBLIC_MAXIMUM_MEDIA_FILE_SIZE_MB) || 1200;

export const MAXIMUM_MEDIA_FILE_SIZE_BYTES = MAXIMUM_MEDIA_FILE_SIZE_MB * 1024 * 1024;

export const MAXIMUM_MEDIA_LENGTH_MINUTES =
    parseInt(process.env.NEXT_PUBLIC_MAXIMUM_MEDIA_LENGTH_MINUTES) || 180;
	
export const MAXIMUM_MEDIA_LENGTH_SECONDS = MAXIMUM_MEDIA_LENGTH_MINUTES * 60;


export const INSIGHT_PRIORITY_OPTIONS=[
	{
		label: 'Critical',
		color:'#C14540',
		icon:Priority_Urgent,
		value: 5
	},
	{
		label: 'High',
		color:'#F45567',
		icon:Priority_High,
		value: 4
	},
	{
		label: 'Medium',
		color:'#FE911F',
		icon:Priority_Medium,
		value: 3
	},
	{
		label: 'Low',
		color:'#F9CF45',
		icon:Priority_Low,
		value: 2
	}
]

export const InformationDetails={
    label:'Interview Details',
    color:'#FFFFFF',
    icon:Information_details,
}

export const SUPPORTED_LANGUAGES = [
  { key: "Bulgarian", value: "Bulgarian" },
  { key: "Catalan", value: "Catalan" },
  { key: "Chinese", value: "Chinese" },
  {
    key: "Chinese (Mandarin, Simplified)",
    value: "Chinese (Mandarin, Simplified)"
  },
  {
    key: "Chinese (Mandarin, Traditional)",
    value: "Chinese (Mandarin, Traditional)"
  },
  {
    key: "Chinese (Cantonese, Traditional)",
    value: "Chinese (Cantonese, Traditional)"
  },
  { key: "Czech", value: "Czech" },
  { key: "Danish", value: "Danish" },
  { key: "Danish (Denmark)", value: "Danish (Denmark)" },
  { key: "Dutch", value: "Dutch" },
  { key: "English", value: "English" },
  { key: "English (US)", value: "English (US)" },
  { key: "English (Australian)", value: "English (Australian)" },
  { key: "English (UK)", value: "English (UK)" },
  { key: "English (New Zealand)", value: "English (New Zealand)" },
  { key: "English (Indian)", value: "English (Indian)" },
  { key: "Estonian", value: "Estonian" },
  { key: "Finnish", value: "Finnish" },
  { key: "Flemish", value: "Flemish" },
  { key: "French", value: "French" },
  { key: "French (Canada)", value: "French (Canada)" },
  { key: "German", value: "German" },
  { key: "German (Switzerland)", value: "German (Switzerland)" },
  { key: "Greek", value: "Greek" },
  { key: "Hindi", value: "Hindi" },
  { key: "Hungarian", value: "Hungarian" },
  { key: "Indonesian", value: "Indonesian" },
  { key: "Italian", value: "Italian" },
  { key: "Japanese", value: "Japanese" },
  { key: "Korean", value: "Korean" },
  { key: "Latvian", value: "Latvian" },
  { key: "Lithuanian", value: "Lithuanian" },
  { key: "Malay", value: "Malay" },
  { key: "Norwegian", value: "Norwegian" },
  { key: "Polish", value: "Polish" },
  { key: "Portuguese", value: "Portuguese" },
  { key: "Portuguese (Brazil)", value: "Portuguese (Brazil)" },
  { key: "Portuguese (Portugal)", value: "Portuguese (Portugal)" },
  { key: "Romanian", value: "Romanian" },
  { key: "Russian", value: "Russian" },
  { key: "Slovak", value: "Slovak" },
  { key: "Spanish", value: "Spanish" },
  { key: "Spanish (Latin America)", value: "Spanish (Latin America)" },
  { key: "Swedish", value: "Swedish" },
  { key: "Swedish (Sweden)", value: "Swedish (Sweden)" },
  { key: "Thai", value: "Thai" },
  { key: "Turkish", value: "Turkish" },
  { key: "Ukrainian", value: "Ukrainian" },
  { key: "Vietnamese", value: "Vietnamese" }
];

export const UserRoles = {
    "Team Member": "TeamMember",
    Mentor : "Mentor",
    Observer : "Observer",
    Administrator:"Administrator",
    "System Administrator" : "SystemAdministrator"
  };
  
export const CohortRoles = {
    "Cohort Administrator" : "CohortAdministrator",
    Instructor: "Instructor",
    Observer : "Observer",
    Administrator:"Administrator",
    "System Administrator" : "SystemAdministrator"
  };


export const WeekEndDay={
    Saturday : "Saturday",
    Sunday : "Sunday",
    Monday : "Monday",
    Tuesday : "Tuesday",
    Wednesday : "Wednesday",
    Thursday : "Thursday",
    Friday : "Friday"
}

export const TimeZone = {
    PST : "PST",
    CST : "CST",
    EST : "EST",
}


export const cohortPolicyConfig= {
    "Are Presenations Team Member Accessible" : "canMembersViewPresentations",
    "Are Presentation Comments Team Member Accessible" : "canMembersViewPresentationFeedback",
    "Is The Private Instructor Presentation Chat Enabled" : "isPrivatePresentationChatVisible" ,
    "Can Teams See Each Other" : "visibility",
    "Is General Chat Enabled" : "isGeneralVisible",
    "Is Instructor Chat Enabled" : "isInstructorChatVisible",
    "Is Instructor Feedback Chat Enabled" : "isInstructorFeedbackVisible",
    "Is Instructor General Chat Enabled" : "isInstructorGeneralVisible",
    "Show interviews created before the cohort start date in the cohort statistics." : "isInterviewVisibleInStatsBeforeStartDate",
    "Are Presentations/Scoring Features Enabled" : "presentationEnabled",
    "Are Instructor Engagements Enabled In The Dashboard" : "isInstructorEngagementsVisible",
}

export const ADMIN_VALID_EMAIL = [
  "rebecca@innovationwithin.com",
  "james@innovationwithin.com",
  "shohan@innovationwithin.com",
  "justin@innovationwithin.com"
];