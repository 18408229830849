import axios from "axios";
import { DiscoverySession } from "../stores/jotai/discovery-session";
import { noop } from "lodash";
import { setOnSuccessfulMigration, setV3MigrationStatus } from "../stores/jotai/migration/v3MigrationAtom";

export const checkForV3Migration = (
    session: DiscoverySession,
    keycloakToken: string,
    setV3MigrationAtomState: Function,
    onSuccess: Function = noop,
    onNotFound: Function = noop,
    onFailure: Function = noop
) => {
    axios
        .get(
            `${process.env.NEXT_PUBLIC_LINX_URL}/migration/${session?.session?.groupID}/v3`,
            {
                params: {
                    origin: "v3",
                    userID: session?.user?.userID,
                },
                withCredentials: false,
                headers: {
                    Authorization: "Bearer " + keycloakToken,
                },
            }
        )
        .then((response) => {
            if (response.status === 200) {
                onSuccess();
            }
        })
        .catch((error) => {
            const errorStatus = error?.response?.status;

            if (errorStatus === 404) {
                setV3MigrationStatus(setV3MigrationAtomState, true);
                setOnSuccessfulMigration(setV3MigrationAtomState, onSuccess);
                onNotFound();
            } else {
                onFailure(error);
            }
        });
};
