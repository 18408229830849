import { Chip } from "@mui/material";
import { Query, QueryClient } from "@tanstack/react-query";
import axios from "axios";
import { atom } from "jotai";
import { focusAtom } from "jotai-optics";
import { splitAtom } from "jotai/utils";
import { clone, flatten, get, noop } from "lodash";
import moment from "moment";
import {
  HYPOTHESIS_ACTIVITIES,
  INTERVIEW_NAVIGATION_TABS,
  PUBNUB_MESSAGE_ACTION,
  PUBNUB_MESSAGE_TYPE
} from "../../constants";
import {
  Artifact,
  ArtifactMediaData
} from "../../tanstackQuery/queries/artifacts/type";
import { appLog } from "../../util";
import { sortEntries } from "../../util/entries";
import isValidCanvasActivity from "../../util/isValidCanvasActivity";
import { InterviewArray } from "./interviews/interviews";
import { protectedApiCall } from "../../api/network";
import { TeamCollections } from "./teams/teams";
import { toast } from "sonner";
import { renderErrorToast } from "../../../lib/ErrorToastMessage";
import { any } from "async";

export const DEFAULT_SELECTED_WEEK = 0;

export interface IRole {
  roleID: string;
  name: string;
  created: string;
  modified: string;
  title: string;
  permissions: string;
  userID: string;
  groupID: string;
  createdF: string;
}

export interface IUser {
  userID: string;
  name: string;
  created: string;
  modified: string;
  email: string;
  phone: string;
  modifiedPassword: string;
  username: string;
  photoURL: string;
  website: string;
  authCode: string;
  stripeCustomerID: string;
  failedLoginAttempts: number;
  announcementID: string;
  showInStats: number;
  createdF: string;
  oryStatus: number;
  foreignAuthKey: string;
  accessToken: string;
  accessed: string;
  expired: string;
  roles: IRole[];
}

export interface IChannel {
  key: string;
  value: string;
}

export interface ITeam {
  id: string;
  name: string;
  role: string;
  permissions: string[];
  org: string;
  orgID: string;
  groupID: string;
  chartDataUrl: string;
  canMembersViewPresentationFeedback: number;
}

export interface IPeriod {
  groupPeriodID: string;
  created: string;
  modified: string;
  groupID: string;
  endDate: string;
  goal: number;
  createdF: string;
}

export interface ICohortComment {
  iterator: number;
  rating: string;
  content: string;
  slug: string;
  period?: string;
  groupID: string;
  userID?: string;
  cohortID: string;
  isSeen: boolean;
  id: string;
  created: string;
  modified: string;
  commentID: string;
  user: IUser;
}

export interface ICohort {
  id: string;
  name: string;
  team: string;
  teams: ITeam[];
  members: string[];
  groupID: string;
  createdAt: string;
  updatedAt: string;
  periods: IPeriod[];
  comments: ICohortComment[];
  chartDataUrl: string;
  periodLabel: string;
  ratings: string;
  canMembersViewPresentations: number;
  canMembersViewPresentationFeedback: number;
}

interface IFolder {
  id: string;
  name: string;
  team: string;
  createdAt: string;
  updatedAt: string;
  parent: string;
}

export interface IActivity {
  activityID: string;
  category: string;
  categoryAbbreviation: string;
  created: string;
  createdF: string;
  createdSortable: string;
  entryID: string;
  finding: string;
  findingID: string;
  groupID: string;
  hypothesis: string;
  hypothesisState: string;
  interview: string;
  interviewID: string;
  name: string;
  targetCategory: string;
  targetCategoryAbbreviation: string;
  targetEntryID: string;
  targetHypothesis: string;
  userID: string;
  parentActivityID: string;
}

export interface BASEStateInterface {
  joyrideButtonClicked: boolean;
  user: IUser;
  team: ITeam;
  pubStore: any;
  cohort: ICohort;
  cohorts: ICohort[];
  folders: IFolder | {};
  org: any;
  orgs: any[];
  transcriptPlayNextFrame: any;
  playbackRate: number;
  BASE: any;
  roles: any;
  helpOpen: boolean;
  discoveryNavigationOpen: boolean;
  artifactCurrentEditor: any;
  uppyFiles: any;
  canvas_view: any;
  selectedFinding: any;
  readyToLogout: boolean;
  pathRequest: string;
  uploadReservation: string;
  interviewEditMode: boolean;
  editingInterview: any;
  selectedInterview: string;
  currentTimeStamp: number;
  newInterviewOpen: boolean;
  showingCanvasNotes: boolean;
  viewTemplatesOpen: boolean;
  discoveryFocusMode: boolean;
  selectedArtifactType: string;
  selectedVideo: string;
  slectedVideoWikiSectionID: string;
  videoPopOut: boolean;
  primaryClickMenuSelection: string;
  insightSelection: any;
  app: any;
  loading: boolean;
  canvasCommentTarget: string;
  discoveryViewMode: string;
  canvasInsightTarget: string;
  canvasLinkTarget: string;
  orgTreeSticky: boolean;
  canvasCanvasOptionsOpen: boolean;
  canvasCanvasFiltersOpen: boolean;
  notificationSettingsOpen: boolean;
  selectedInterviewIndex?:number;
  unread: any;
  selectedGroup: {
    childID: string;
    parentID: string;
    group: {
      groupID: string;
      visibility: string;
    };
    children: any[];
  };
  snackbarQueue: any;
  members: any;
  findingTypes: any;
  canvasSliderValue: number;
  history: any;
  wikiExpansion: any;
  favoritedTagsVisibility: boolean;
  ui: any;
  appState: any;
  roleSnapshot: any;
  encodedMediaArtifact: any;
  isAiInsightGenerationEnabled: boolean;
  pageRequest: string;
}

export const INITIAL_BASE_ATOM_VALUE: BASEStateInterface = {
  ui: {
    canvas_filter_showValidated: true,
    canvas_filter_showInvalidated: true,
    canvas_filter_showUnresolved: true
  },
  pubStore: {},
  //@ts-ignore
  user: {},
  //@ts-ignore
  team: {},
  //@ts-ignore
  cohort: {
    periods: []
  },
  //@ts-ignore
  cohorts: {},
  helpOpen: false,
  artifactCurrentEditor: {},
  unread: {},
  primaryClickMenuSelection: undefined,
  discoveryNavigationOpen: false,
  transcriptPlayNextFrame: false,
  interviewEditMode: false,
  playbackRate: 1.0,
  currentTimeStamp: 0,
  uppyFiles: [],
  selectedFinding: undefined,
  wikiExpansion: {},
  canvas_view: {
    toggle_orientation: false,
    color_view: false,
    comment_view: false,
    insight_view: false,
    hypo_view: false
  },
  snackbarQueue: [],
  discoveryFocusMode: false,
  uploadReservation: undefined,
  showingCanvasNotes: false,
  selectedVideo: "",
  slectedVideoWikiSectionID: "",
  videoPopOut: false,
  newInterviewOpen: false,
  readyToLogout: false,
  viewTemplatesOpen: false,
  discoveryViewMode: INTERVIEW_NAVIGATION_TABS.DRAFT,
  selectedArtifactType: "notes",
  org: {},
  folders: {},
  BASE: {},
  insightSelection: undefined,
  members: [],
  wiki: {},
  editingInterview: false,
  selectedInterview: undefined,
  selectedGroup: undefined,
  orgTreeSticky: true,
  canvasCommentTarget: undefined,
  canvasInsightTarget: undefined,
  canvasLinkTarget: undefined,
  canvasSliderValue: -1,
  canvasCanvasOptionsOpen: false,
  canvasCanvasFiltersOpen: false,
  notificationSettingsOpen: false,
  hierarchy: [],
  flatHierarchy: [],
  history: {},
  roles: [],
  findingTypes: [],
  pathRequest: "",
  favoritedTagsVisibility: true,
  app: {
    app: {
      chatOpen: false,
      notificationSettingsOpen: false,
      notificationOpen: false,
      orgTreeOpen: true,
      selectedData: { period: 1 },
      invalidToken: false,
      validLicense: true,
      readyToLogout: false,
      validLicenseExpireDate: "none",
      isFetchingLicense: false,
      currentArtifactTab: 0,
      isBeaconLoaded: false,
      presentationWeek: 0,
      presentationGroup: undefined,
      presentationRefreshFlag: false,
      isFetching: false,
      unreadNotifications: 0,
      userEditLastMessageFlag: 0,
      toastQueue: []
    },
    support: {
      suggestions: [{ path: "/canvas", role: "*", article: "" }]
    },
    canvas: {
      orientation: "flex",
      showLinkedFindingBadges: true,
      showLinkedHypothesisBadges: false,
      showAllComments: false,
      showLinksByColor: false,
      nubMap: {}
    },
    discovery: {
      interviewList: true,
      transcriptAutoplay: true,
      isFetchingExports: false,
      isFetchingNotes: false,
      isFetchingTranscript: false,
      showConflictPrompt: false,
      conflictPromptUsernameCache: "",
      isSavingArtifactText: false
    }
  },
  loading: true,
  roleSnapshot: {},
  orgs: [],
  encodedMediaArtifact: [],
  isAiInsightGenerationEnabled: false,
  pageRequest: ""
};

export const baseAtom = atom(INITIAL_BASE_ATOM_VALUE);
export const cohortAtom = focusAtom(baseAtom, (optic) => optic.prop("cohort"));
export const teamAtom = focusAtom(baseAtom, (optic) => optic.prop("team"));
export const userAtom = focusAtom(baseAtom, (optic) => optic.prop("user"));
export const periodsAtom = focusAtom(cohortAtom, (optic) =>
  optic.prop("periods")
);
export const orgtreeStickyAtom = focusAtom(baseAtom, (optic) =>
  optic.prop("orgTreeSticky")
);
export const orgTreeVisibleAtom = focusAtom(baseAtom, (optic) =>
  optic.prop("app").prop("orgTreeOpen")
);

export const periodAtomsAtom = splitAtom(periodsAtom);

export function getArtifactByID(
  artifactID: string,
  artifacts: ArtifactMediaData
) {
  const artifactsMain = Object.keys(artifacts);
  let artifactFound = undefined;

  for (let i = 0; i < artifactsMain?.length; i++) {
    const artifactObj = artifacts[artifactsMain[i]] as {
      [key: string]: Artifact;
    };
    artifactFound = Object.values(artifactObj).find(
      (artifact) => artifact?.artifactID === artifactID
    );

    if (artifactFound) {
      break;
    }
  }

  return artifactFound;
}

const DEFAULT_STATE = false;
export function getUIStateIsOptionSelected(base: any, option: string) {
  try {
    if (base === undefined || base.ui === undefined) return DEFAULT_STATE;

    const result = base?.ui[option];

    if (result === true || result === false) {
      return result;
    }
  } catch (e) {
    appLog(e);
  }

  return DEFAULT_STATE;
}

export const blockListedBase = (base: any, blockList: string[]) => {
  let newBase = { ...base };

  for (let i = 0; i < blockList.length; i++) {
    const block = blockList[i];

    if (newBase[block] !== undefined) {
      newBase[block] = undefined;
    }
  }

  return newBase;
};

export function setAIGenerateEnable(set, isAiInsightGenerationEnabled) {
  set((baseState) => ({
    ...baseState,
    isAiInsightGenerationEnabled: isAiInsightGenerationEnabled
  }));
}

// if target is undefined it's a toggle behaviour
export function setUIStateIsOptionSelected(
  set: any,
  base: any,
  option: string,
  target?: boolean
) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    if (newBaseState.ui === undefined || newBaseState.ui === null) {
      newBaseState.ui = {};
    }

    if (target === undefined) {
      target = !getUIStateIsOptionSelected(base, option);
    }

    newBaseState.ui[option] = target;

    return newBaseState;
  });
}

export function setArtifactSessionOwner(
  set: any,
  artifactID: string,
  userID: string
) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.artifactCurrentEditor[artifactID] = userID;

    return newBaseState;
  });
}

export function getAppState(base: any) {
  if (base.app !== undefined) {
    if (base.app !== undefined) {
      return base.app;
    }
  }

  return {
    app: {
      chatOpen: false,
      notificationSettingsOpen: false,
      notificationOpen: false,
      orgTreeOpen: true,
      selectedData: { period: 1 },
      invalidToken: false,
      validLicense: true,
      readyToLogout: false,
      validLicenseExpireDate: "none",
      isFetchingLicense: false,
      currentArtifactTab: 0,
      isBeaconLoaded: false,
      presentationWeek: 0,
      presentationGroup: undefined,
      presentationRefreshFlag: false,
      isFetching: false,
      unreadNotifications: 0,
      userEditLastMessageFlag: 0,
      toastQueue: []
    },
    support: {
      suggestions: [{ path: "/canvas", role: "*", article: "" }]
    },
    canvas: {
      orientation: "flex",
      showLinkedFindingBadges: true,
      showLinkedHypothesisBadges: false,
      showAllComments: false,
      showLinksByColor: false,
      nubMap: {}
    },
    discovery: {
      interviewList: true,
      transcriptAutoplay: true,
      isFetchingExports: false,
      isFetchingNotes: false,
      isFetchingTranscript: false,
      showConflictPrompt: false,
      conflictPromptUsernameCache: "",
      isSavingArtifactText: false
    }
  };
}

export function calculateCanvasActivity(activities: any) {
  // generate a sub-set of the normal activity feed for the canvas slider
  let baseActivity = activities;

  if (baseActivity === undefined || activities?.activity?.length === 0) {
    baseActivity = [];
  }

  return baseActivity?.filter((activity) => isValidCanvasActivity(activity));
}

export function params_getSlider(base: any, activities) {
  const a = calculateCanvasActivity(activities);

  const max = a ? a.length : -1;

  let slider = base.canvasSliderValue;

  if (slider < 0) {
    if (a && a.length === 0) {
      slider = max;
    }
  }

  // prevent run-away slider values
  if (slider > max) {
    slider = max;
  }

  return {
    canvasSliderMax: max,
    canvasSliderValue: slider,
    isMax: base.canvasSliderValue === -1 ? true : max === slider
  };
}

export function setCanvasPeriodValue(set: any, target: any) {
  set((_canvas) => {
    let newCanvasState = {
      ..._canvas
    };

    newCanvasState.selectedWeek = target;

    return newCanvasState;
  });
}

export function setCanvasSliderValue(set: any, target: number) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.canvasSliderValue = target;

    return newBaseState;
  });
}

export function setNewInterviewOpen(set: any, target: boolean) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.newInterviewOpen = target;

    return newBaseState;
  });
}

export function toggleSticky(set: any, target: boolean) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.orgTreeSticky = target;

    return newBaseState;
  });
}

export function setInsightSelection(set: any, target: any) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.insightSelection = target;
    //newBaseState.selectedFinding = undefined;

    return newBaseState;
  });
}

export function setSelectedArtifactType(set: any, target: string) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.selectedArtifactType = target;

    return newBaseState;
  });
}

export function getTopOfSnackbarQueue(set: any, cb: any) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    if (newBaseState?.snackbarQueue?.length > 0) {
      const firstItem =
        newBaseState.snackbarQueue[newBaseState.snackbarQueue.length - 1];

      newBaseState.snackbarQueue = newBaseState.snackbarQueue.slice(
        0,
        newBaseState.snackbarQueue.length - 1
      );

      cb(firstItem);

      return newBaseState;
    } else {
      cb(undefined);

      return newBaseState;
    }
  });
}

//using from our new one
export function getValidFindLinks(findings: any, findingLinks: any) {
  let filteredFindings = [];
  const findingLink = findingLinks || [];

  for (let i = 0; i < findingLink?.length; i++) {
    const findingID = findingLink[i].findingID;
    const finding = findings?.[findingID];

    if (finding !== undefined && !finding?.isDraft) {
      filteredFindings.push(findingLink[i]);
    }
  }

  return filteredFindings;
}

export function getEntryById(canvas: any, entryID: string) {
  // if this gets lagy we can optimize it here, so move each
  // use of this behaviour to this entry point
  let match = {};

  try {
    for (let i = 0; i < canvas?.categories?.length; i++) {
      for (let e = 0; e < canvas?.categories[i]?.entries?.length; e++) {
        if (canvas.categories[i].entries[e].entryID === entryID) {
          match = canvas.categories[i].entries[e];

          // @ts-ignore
          match.category = canvas.categories[i];
        }
      }
    }
  } catch (e) {}

  return match;
}

export function softSelect(
  set: any,
  target: string,
  flatHierarchy: any,
  teams: TeamCollections
) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };
    return {
      ..._base,
      selectedGroup: calculateSelectedGroup(
        newBaseState,
        target,
        flatHierarchy,
        teams
      )
    };
  });
}

export function setIsInterviewInEditMode(set: any, target: any) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.interviewEditMode = target;

    return newBaseState;
  });
}

export function selectEditingInterview(set: any, target: any) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.editingInterview = target;

    return newBaseState;
  });
}

//Load -team will be replaced soon
export function selectInterviewWithInsight(
  set: any,
  target: string,
  insightTarget: any,
  interviews: InterviewArray
) {
  set((_base) => {
    let newBaseState: BASEStateInterface = {
      ..._base
    };
    const interviewStatus = interviews?.find(
      (interview) => interview.interviewID === target
    )?.status;

    try {
      //@ts-ignore
      window.__lastInsightSelection = undefined;
    } catch (e) {}

    newBaseState.insightSelection = undefined;
    newBaseState.selectedFinding = insightTarget;
    newBaseState.selectedInterview = target;
    newBaseState.discoveryViewMode = interviewStatus?.toLowerCase();
    newBaseState.interviewEditMode = false;

    return newBaseState;
  });
}

export function selectInterview(set: any, target: string, interviews: any, rowIndex?: number ) {
  set((_base) => {
    let newBaseState: BASEStateInterface = {
      ..._base
    };
    const interviewStatus = interviews?.find(
      (interview) => interview.interviewID === target
    )?.status;

    try {
      //@ts-ignore
      window.__lastInsightSelection = undefined;
    } catch (e) {}

    newBaseState.insightSelection = undefined;
    newBaseState.selectedInterview = target;
    newBaseState.interviewEditMode = false;
    newBaseState.discoveryViewMode = interviewStatus?.toLowerCase();
    newBaseState.selectedArtifactType = "notes";

    if(rowIndex){
      newBaseState.selectedInterviewIndex = rowIndex;
    }
    
    return newBaseState;
  });
}

export function selectCanvasLink(set: any, target: string) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.canvasLinkTarget = target;

    return newBaseState;
  });
}

export function refreshDiscoveryViewMode(set: any, allInterview: any) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.selectedInterview = getFirstInterview(
      allInterview,
      newBaseState.discoveryViewMode
    );

    return newBaseState;
  });
}

export function setDiscoveryViewMode(
  set: any,
  request: string,
  selectFirstInterview = true,
  allInterview: any
) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.discoveryViewMode = request;

    if (selectFirstInterview) {
      newBaseState.selectedInterview = getFirstInterview(allInterview, request);
    }

    return newBaseState;
  });
}

export function getViewMode(base, interviewData) {
  const mode = base.discoveryViewMode;

  if (mode === INTERVIEW_NAVIGATION_TABS.DRAFT) {
    return mode;
  }

  let foundAny = false;
  for (let i = 0; i < interviewData.length; i++) {
    const ii = interviewData[i];

    if (ii.status === base.discoveryViewMode) {
      foundAny = true;
    }
  }

  if (foundAny) {
    return base.discoveryViewMode;
  } else {
    return INTERVIEW_NAVIGATION_TABS.DRAFT;
  }
}

export function setPlaybackRate(set: any, request: any) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.playbackRate = request;

    return newBaseState;
  });
}

export function setOrgToggle(set: any, target: boolean) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.app.orgTreeOpen = target;

    return newBaseState;
  });
}

export function setDiscoveryFocusMode(set: any, target: boolean) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.discoveryFocusMode = target;

    return newBaseState;
  });
}

export function setViewTemplatesOpen(set: any, target: boolean) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.viewTemplatesOpen = target;

    return newBaseState;
  });
}

export function selectVideo(set: any, target: string, wikiSectionID: string) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.selectedVideo = target;
    newBaseState.slectedVideoWikiSectionID = wikiSectionID;

    return newBaseState;
  });
}

// uppyFiles
export function setUppyFiles(set: any, target: any) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.uppyFiles = target;

    return newBaseState;
  });
}

export function setCurrentTranscriptionForcePlayFlag(set: any, target: any) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };

    newBaseState.transcriptPlayNextFrame = target;

    return newBaseState;
  });
}

export function setLastExpandedWikiSection(
  set,
  wikiID: string,
  sectionID: string
) {
  set((s) => {
    let buffer = {
      ...s
    };

    if (buffer.wikiExpansion === undefined) {
      buffer.wikiExpansion = {
        empty: true
      };
    }

    buffer.wikiExpansion[wikiID] = sectionID;

    return buffer;
  });
}

export function setLoadingOrg(set, target: boolean) {
  set((s) => {
    let buffer = {
      ...s
    };

    buffer.loading = target;

    return buffer;
  });
}

export function storeMessageInChannel(set, channel, message: any) {
  set((s) => {
    let buffer = {
      ...s
    };

    let updateFound = false;

    if (buffer.history?.[channel] !== undefined) {
      try {
        for (let i = 0; i < buffer?.history?.[channel]?.length; i++) {
          const ii = buffer.history[channel][i];

          const newMessageID = message.id || message.chatID;
          const existingMessageID = ii.id || ii.chatID;

          if (
            newMessageID &&
            existingMessageID &&
            newMessageID === existingMessageID
          ) {
            buffer.history[channel][i] = { ...ii, ...message };
            updateFound = true;
            break;
          }
        }
      } catch (e) {}
    }

    if (updateFound === false) {
      try {
        buffer.history[channel].push({
          ...message,
          chatID: message.id,
          created: new Date().toUTCString()
        });
      } catch (e) {
        appLog(e);
      }
    }

    return buffer;
  });
}

export function isAnyRole(base: any) {
  const keysToCheck = [
    "isAdmin",
    "isCohortAdmin",
    "isInstructor",
    "isMentor",
    "isObserver",
    "isSystemAdministrator",
    "isTeamMember"
  ];

  let anyMatches = false;

  for (let i = 0; i < keysToCheck.length; i++) {
    if (checkRole(base, keysToCheck[i])) {
      anyMatches = true;
    }
  }

  return anyMatches;
}

export function getChannels(base: any) {
  let user = base.user;
  let group = base.team;
  let cohort = base.cohort;

  let team: any;
  if (group) {
    if (group.type === "Team") team = group as any;
    if (group.type === "Cohort") cohort = group as any;
  }

  let items: any[] = [];

  let isInstructor = false,
    isCohortAdmin = false,
    isCohortMember = false;

  let isMemberOfTeam = team ? isAnyRole(base) : false;
  let isSystemAdmin = checkRole(base, "isSystemAdministrator");

  let useInstructorChat = false;
  let useInstructorFeedback = false;

  if (team) {
    useInstructorChat = team.isInstructorChatVisible === 1;
    useInstructorFeedback = team.isInstructorFeedbackVisible === 1;
  }

  // Cohort's policies take precedent if they exist
  if (cohort) {
    useInstructorChat = cohort.isInstructorChatVisible === 1;
    useInstructorFeedback = cohort.isInstructorFeedbackVisible === 1;
  }

  if (user) {
    if (cohort) {
      isInstructor = checkRole(base, "isInstructor");
      isCohortAdmin = checkRole(base, "isCohortAdmin");
      isCohortMember = isAnyRole(base);

      if (cohort.isInstructorGeneralVisible === 1) {
        if (isInstructor || isCohortAdmin || isSystemAdmin)
          items.push({ key: cohort.id + "-IC", value: "Instructor General" });
      }

      if (cohort.isGeneralVisible === 1) {
        if (isMemberOfTeam || isCohortMember || isSystemAdmin)
          items.push({ key: cohort.id + "-GC", value: "General Channel" });
      }
    }

    if (team) {
      if (isMemberOfTeam) {
        if (isInstructor === false) {
          items.push({ key: team.id + "-TC", value: "Team Chat" });
        }
      }

      if (useInstructorChat) {
        if (isInstructor || isCohortAdmin || isSystemAdmin)
          items.push({ key: team.id + "-IC", value: "Instructor Chat" });
      }

      if (useInstructorFeedback) {
        if (isInstructor || isMemberOfTeam || isSystemAdmin) {
          items.push({ key: team.id + "-PF", value: "Instructor Feedback" });
        }
      }

      if (cohort && (isMemberOfTeam || isCohortMember || isSystemAdmin)) {
        items.push({ key: team.id + "-PE", value: "Peer Feedback" });
      }
    }
  }

  return items;
}

export function postMessageToChannel(
  session,
  set,
  channel,
  message: any,
  onSuccess?: Function,
  onFailure?: Function
) {
  // store it locally
  storeMessageInChannel(set, channel, message);

  // send off a persistence request
  protectedApiCall(
    session,
    "chat",
    {
      ...message,
      isANotification: true,
      silent: false,
      channelID: channel
    },
    {},
    "post"
  )
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onFailure(error);
    });
}

export function loadChannelHistory(
  set,
  channel,
  token,
  onSuccess = noop,
  onFailure = noop
) {
  axios
    .get(`${process.env.NEXT_PUBLIC_LINX_URL}/refactor/history`, {
      params: {
        channel: channel
      },
      withCredentials: false,
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then((res) => {
      set((s) => {
        let buffer = {
          ...s
        };

        buffer.history[channel] = res.data;

        return buffer;
      });
      onSuccess(res);
    })
    .catch((error) => {
      onSuccess(error);
    });
}

export const setUnreadMessageCount = (setBase, channelID: string) => {
  setBase((base: BASEStateInterface) => {
    const newBaseState = {
      ...base
    };

    if (newBaseState.unread.hasOwnProperty(channelID)) {
      newBaseState.unread[channelID] = newBaseState.unread[channelID] + 1;
    } else {
      newBaseState.unread[channelID] = 1;
    }

    return newBaseState;
  });
};

export const markChannelMessagesAsRead = (setBase, channelID: string) => {
  setBase((base) => {
    const newBaseState = {
      ...base
    };

    newBaseState.unread[channelID] = 0;

    return newBaseState;
  });
};

export function getFirstInterview(interviews, targetStatus) {
  if (interviews === undefined || interviews.length === 0) return {};
  let sorted = interviews.sort((a, b) => {
    return moment(a.created).isBefore(moment(b.created)) ? 1 : -1;
  });

  let interviewID = undefined;

  for (let i = 0; i < sorted?.length; i += 1) {
    if (interviewID === undefined) {
      let sStatus = sorted[i].status;

      if (sStatus === undefined || sStatus === null || sStatus === "")
        sStatus = "draft";

      if (sStatus === targetStatus) {
        interviewID = sorted[i].interviewID;

        return interviewID;
      }
    }
  }

  return interviewID;
}

export function getAssemblyTypeFromInterview(assemblies, interviewID) {
  let match = undefined;

  if (assemblies === undefined || assemblies === undefined) return undefined;

  for (let i = 0; i < assemblies?.length; i++) {
    const t = assemblies[i];

    if (interviewID !== undefined) {
      if (t.reservation === interviewID || t.interviewID === interviewID) {
        match = t;
      }
    }
  }

  if (match === undefined) return match;

  return match.type;
}

export function recieveArtifact(set, artifact) {
  addEncodedMedia(set, artifact);
}

export const removeInterviewByID = (
  set,
  interviewID: string,
  interviews: InterviewArray
) => {
  set((base: BASEStateInterface) => {
    const newBase = { ...base };

    if (newBase.selectedInterview === interviewID) {
      newBase.selectedInterview = getFirstInterview(
        interviews,
        newBase.discoveryViewMode
      );
    }

    return newBase;
  });
};

export const addEncodedMedia = (set, artifact) => {
  set((base: BASEStateInterface) => {
    const newBase = { ...base };

    newBase.encodedMediaArtifact.push({ ...artifact });

    return newBase;
  });
};

export function setShowCanvasNotes(set, target) {
  set((base) => {
    let buffer = {
      ...base
    };

    buffer.showingCanvasNotes = target;

    return buffer;
  });
}

export function selectInsight(set, target) {
  set((base) => {
    let buffer = {
      ...base
    };

    buffer.selectedFinding = target;

    return buffer;
  });
}

export function getIsEntryLinkedToEntry(canvasData, sourceEntry, targetEntry) {
  for (let i = 0; i < canvasData?.linkSet?.length; i++) {
    if (canvasData.linkSet[i].entryID === sourceEntry) {
      if (canvasData.linkSet[i].targetEntryID === targetEntry) {
        return true;
      }
    }
    if (canvasData.linkSet[i].targetEntryID === sourceEntry) {
      if (canvasData.linkSet[i].entryID === targetEntry) {
        return true;
      }
    }
  }

  return false;
}

export function getDisplayableActivityFeed(activities) {
  for (let i = 0; i < activities?.length; i++) {
    const activityEntry = activities[i];

    let display = {
      title: "",
      subtext: "",
      date: moment(activityEntry.created).format("MMM DD 'YY"),
      chip: <Chip label="-" variant="outlined" />
    };

    if (activityEntry.name === "HypothesisStateChange") {
      display.title = "Hypothesis State Change";
      display.subtext = `${activityEntry.hypothesis} was ${activityEntry.hypothesisState}`;
      display.chip = <Chip label="Canvas" variant="outlined" />;
    } else if (activityEntry.name === "HypothesisLink") {
      display.title =
        "Hypothesis Linked to " + activityEntry.targetCategoryAbbreviation;
      display.subtext = `${activityEntry.hypothesis} was linked to ${activityEntry.targetHypothesis}`;
      display.chip = <Chip label="Canvas & Insights" variant="outlined" />;
    }

    activities[i].display = display;
  }

  return activities;
}

export function updateBaseFinding(setBase, finding) {
  return setBase((base) => {
    const buffer = { ...base };

    buffer.selectedFinding = finding;

    return buffer;
  });
}

export function recieveFinding(set, finding, selectIt) {
  set((base) => {
    let buffer = {
      ...base
    };

    if (finding.deleted === 1) {
      if (buffer.selectedFinding?.findingID === finding.findingID) {
        buffer.selectedFinding = null;
      }

      return buffer;
    }

    return buffer;
  });
}

export function recieveLinxPayload(
  set,
  m,
  isAiInsightGenerationEnabled,
  client: QueryClient
) {
  /*
    actualChannel: null;
    channel: "276c2d37-cdf2-4372-bc88-77b5c61964df-linx";
    message: {
        channel: '276c2d37-cdf2-4372-bc88-77b5c61964df-linx', 
        message: { }
    }
    publisher: "712c5b9c-3996-4e2e-a30c-217cb660a189";
    subscribedChannel: "276c2d37-cdf2-4372-bc88-77b5c61964df-linx";
    subscription: undefined;
    timetoken: "16711229342806522";
  */

  const { source, type, message, action } = m;

  const data = message;

  let ours = false;

  try {
    //@ts-ignore
    if (window.__sid === source) {
      ours = true;
    }
  } catch (e) {}

  if (type === PUBNUB_MESSAGE_TYPE.AI_CANVAS) {
    if (action === PUBNUB_MESSAGE_ACTION.GENERATE && data?.success) {
      // bulkAddEntries(set, data?.entries);
      client.invalidateQueries({ queryKey: ["canvas"] });
    }
  }

  if (type === PUBNUB_MESSAGE_TYPE.AI_TEMPLATE) {
    if (action === PUBNUB_MESSAGE_ACTION.GENERATE && data?.success) {
      client.invalidateQueries({ queryKey: ["templates"] });
    }
  }

  // but don't ignore our own notification updates
  if (type === PUBNUB_MESSAGE_TYPE.NOTIFICATION) {
    client.invalidateQueries({ queryKey: ["notifications"] });
  }

  if (type === PUBNUB_MESSAGE_TYPE.ENTRY) {
    if (action === PUBNUB_MESSAGE_ACTION.BULK_DELETE) {
      client.invalidateQueries({ queryKey: ["activities"] });
    }
  }

  if (type === PUBNUB_MESSAGE_TYPE.AI_INSIGHT) {
    if (action === PUBNUB_MESSAGE_ACTION.GENERATE) {
      if (isAiInsightGenerationEnabled) {
        client.invalidateQueries({ queryKey: ["insights"] });
        client.invalidateQueries({ queryKey: ["finding-links"] });
      }
    }
  }

  if (type === PUBNUB_MESSAGE_TYPE.ACTIVITY) {
    if (action === PUBNUB_MESSAGE_ACTION.BULK_DELETE) {
      client.invalidateQueries({ queryKey: ["activities"] });
    }
  }

  if (type === PUBNUB_MESSAGE_TYPE.ASSEMBLY) {
    client.invalidateQueries({ queryKey: ["assemblies"] });
  }

  if (type === PUBNUB_MESSAGE_TYPE.MEDIA_ASSEMBLY) {
    client.invalidateQueries({ queryKey: ["assemblies"] });
  }

  if (type === PUBNUB_MESSAGE_TYPE.INSIGHT) {
    try {
      if (action === PUBNUB_MESSAGE_ACTION.BULK_DELETE) {
        client.invalidateQueries({ queryKey: ["activities"] });
      }
    } catch (error) {
      console.error(error);
    }
  }

  // ignore our own
  if (ours) return;

  if (type === PUBNUB_MESSAGE_TYPE.ENTRY) {
    if (action === PUBNUB_MESSAGE_ACTION.BULK_DELETE) {
      client.invalidateQueries({ queryKey: ["canvas"] });
      client.invalidateQueries({ queryKey: ["canvas_layout"] });
    }
  }

  if (type === "entry") {
    try {
      client.invalidateQueries({ queryKey: ["canvas"] });
      client.invalidateQueries({ queryKey: ["canvas_layout"] });
    } catch (e) {
      console.error(e);
    }
  }
  if (type === PUBNUB_MESSAGE_TYPE.TEMPLATE) {
    try {
      client.invalidateQueries({ queryKey: ["templates"] });
    } catch (e) {
      console.error(e);
    }
  }
  if (type === PUBNUB_MESSAGE_TYPE.DEEPGRAM) {
    try {
      client.invalidateQueries({ queryKey: ["artifacts"] });
      client.invalidateQueries({ queryKey: ["assemblies"] });
    } catch (e) {
      console.error(e);
    }
  }
  if (type === PUBNUB_MESSAGE_TYPE.AUDIO_ENCODING) {
    recieveArtifact(set, data);
  }
  if (type === PUBNUB_MESSAGE_TYPE.INSIGHT) {
    try {
      client.invalidateQueries({ queryKey: ["insights"] });
      client.invalidateQueries({ queryKey: ["finding-links"] });
    } catch (e) {}
  }

  if (
    type === PUBNUB_MESSAGE_TYPE.CHAT ||
    type === PUBNUB_MESSAGE_TYPE.CHAT_UPDATE ||
    type === PUBNUB_MESSAGE_TYPE.CHAT_DELETE
  ) {
    storeMessageInChannel(set, m.channel, data);

    if (type === PUBNUB_MESSAGE_TYPE.CHAT) {
      setUnreadMessageCount(set, m.channel);
    }
  }

  if (type === "artifactOwner") {
    try {
      setArtifactSessionOwner(set, data.artifactID, data.userID);
    } catch (e) {
      console.error(e);
    }
  }
  if (type === "link") {
    const payload = data.payload;
    const action = data.action;

    client.invalidateQueries({ queryKey: ["canvas"] });
  }
  if (type === "interview") {
    try {
      if (data.name !== undefined) {
        data.interviewee = data.name;
      }
      client.invalidateQueries({ queryKey: ["interviews"] });

      if (data.noteKey !== undefined) {
        client.invalidateQueries({ queryKey: ["artifacts"] });
      }
    } catch (e) {
      appLog(e);
    }
  }
  if (type === "comment") {
    try {
      client.invalidateQueries({ queryKey: ["comments"] });
    } catch (e) {
      appLog(e);
    }
  }
}

export const formatStatus = (status) => {
  if (status === "draft" || status === "published") {
    return status;
  }
  return "draft";
};

export function calculateSelectedGroup(
  base,
  groupID,
  flatHierarchy,
  teamsData
) {
  let target = groupID;

  if (typeof target === "string") {
    // construct this location in the hierarchy in memory
    // isntead of recursively navigating the hierarchy
    let buffer = {
      parentID: "",
      childID: groupID,
      hierarchyID: 0,
      group: {},
      isExpanded: true,
      children: []
    };

    // find the parent
    for (let i = 0; i < flatHierarchy?.length; i++) {
      if (flatHierarchy[i].childID === groupID) {
        buffer.parentID = flatHierarchy[i].parentID;
        buffer.group = base.cohorts?.[flatHierarchy[i].parentID];
      }
    }

    // find the children
    for (let i = 0; i < flatHierarchy?.length; i++) {
      if (flatHierarchy[i].parentID === buffer.parentID) {
        buffer.children.push({
          group: teamsData?.[flatHierarchy[i].childID]
        });
      }
    }

    return buffer;
  } else {
    return target;
  }
}

export function loadOrg(set, catSet, orgID, session, cb?) {
  set((base) => {
    return {
      ...base,
      loading: true
    };
  });

  protectedApiCall(
    session,
    "state/base",
    {},
    {
      params: {
        orgID: orgID
      }
    },
    "get"
  ).then((res) => {
    set((BASE) => {
      let resDataWithIDMapping = {
        ...INITIAL_BASE_ATOM_VALUE,
        //@ts-ignore
        ...res.base
      };

      // map our data to how redux handled it
      resDataWithIDMapping.org.id = resDataWithIDMapping.org.orgID;
      resDataWithIDMapping.team.id = resDataWithIDMapping.team.groupID;
      resDataWithIDMapping.team.type = resDataWithIDMapping.team.groupType;
      resDataWithIDMapping.cohort.type = resDataWithIDMapping.cohort.groupType;
      resDataWithIDMapping.team.teamID = resDataWithIDMapping.team.groupID;
      resDataWithIDMapping.cohort.id = resDataWithIDMapping.cohort.groupID;
      resDataWithIDMapping.cohort.teamID = resDataWithIDMapping.cohort.groupID;
      resDataWithIDMapping.user.roles = resDataWithIDMapping.roles;

      let newState = { ...resDataWithIDMapping };
      newState.app.orgTreeOpen = BASE.app.orgTreeOpen;
      newState.selectedVideo = BASE.selectedVideo;
      newState.slectedVideoWikiSectionID = BASE.slectedVideoWikiSectionID;
      newState.videoPopOut = BASE.videoPopOut;

      newState.readyToLogout = false;
      newState.loading = false;
      newState.canvas_view = INITIAL_BASE_ATOM_VALUE.canvas_view;

      if (cb) {
        cb();
      }

      return newState;
    });
  });
}

export function loadTeam(
  set,
  catSet,
  groupID,
  token,
  session,
  cb?,
  statesWithPreviousValuesToHold: any = {}
) {
  set((base) => {
    return {
      ...base,
      ...statesWithPreviousValuesToHold,
      loading: true
    };
  });

  protectedApiCall(
    session,
    "state/base",
    {},
    {
      params: {
        groupID: groupID
      }
    },
    "get"
  ).then((res: any) => {
    set((BASE) => {
      let resDataWithIDMapping = {
        ...INITIAL_BASE_ATOM_VALUE,
        ...res.base,
        ...statesWithPreviousValuesToHold
      };

      // map our data to how redux handled it
      resDataWithIDMapping.org.id = resDataWithIDMapping.org.orgID;
      resDataWithIDMapping.team.id = resDataWithIDMapping.team.groupID;
      resDataWithIDMapping.team.type = resDataWithIDMapping.team.groupType;
      resDataWithIDMapping.cohort.type = resDataWithIDMapping.cohort.groupType;
      resDataWithIDMapping.team.teamID = resDataWithIDMapping.team.groupID;
      resDataWithIDMapping.cohort.id = resDataWithIDMapping.cohort.groupID;
      resDataWithIDMapping.cohort.teamID = resDataWithIDMapping.cohort.groupID;
      resDataWithIDMapping.user.roles = resDataWithIDMapping.roles;

      let newState = { ...resDataWithIDMapping };
      newState.app.orgTreeOpen = BASE.app.orgTreeOpen;
      newState.selectedVideo = BASE.selectedVideo;
      newState.slectedVideoWikiSectionID = BASE.slectedVideoWikiSectionID;

      newState.readyToLogout = false;
      newState.loading = false;
      newState.canvas_view = BASE.canvas_view;
      newState.ui = INITIAL_BASE_ATOM_VALUE.ui;
      // try { newState.canvasSliderValue = newState.activity.length; } catch (e) { }

      if (cb) {
        cb(newState);
      }

      return newState;
    });

    // Hold selection of insight after calling base api
  });
}

export function loadLibraryExternally(
  session,
  setBASEState,
  catSet,
  token,
  bridgeCallback,
  sessionID,
  orgID?,
  groupID?
) {
  protectedApiCall(
    session,
    "state/base",
    {},
    {
      params: {
        orgID,
        groupID
      }
    },
    "get"
  ).then((res) => {
    setBASEState((BASE) => {
      let resDataWithIDMapping = {
        ...INITIAL_BASE_ATOM_VALUE,

        // @ts-ignore
        ...res.base,
        sessionID
      };

      if (resDataWithIDMapping.discoveryViewMode === undefined) {
        resDataWithIDMapping.discoveryViewMode =
          INTERVIEW_NAVIGATION_TABS.DRAFT;
      }

      // map our data to how redux handled it
      resDataWithIDMapping.org.id = resDataWithIDMapping.org.orgID;
      resDataWithIDMapping.team.id = resDataWithIDMapping.team.groupID;
      resDataWithIDMapping.team.type = resDataWithIDMapping.team.groupType;
      resDataWithIDMapping.cohort.type = resDataWithIDMapping.cohort.groupType;
      resDataWithIDMapping.team.teamID = resDataWithIDMapping.team.groupID;
      resDataWithIDMapping.cohort.id = resDataWithIDMapping.cohort.groupID;
      resDataWithIDMapping.cohort.teamID = resDataWithIDMapping.cohort.groupID;
      resDataWithIDMapping.user.roles = resDataWithIDMapping.roles;

      // make sure that if we're loading an existing state from memory
      // it's from this session
      if (BASE !== undefined) {
        // so, if it doesn't match clear it
        if (BASE.sessionID !== sessionID && sessionID !== undefined) {
          BASE = {};
        }
      }

      let newState = { ...BASE, ...resDataWithIDMapping };
      newState.canvas_view = INITIAL_BASE_ATOM_VALUE.canvas_view;

      newState.readyToLogout = false;
      newState.loading = false;
      // try { newState.canvasSliderValue = newState.activity.length; } catch (e) { }

      newState.app = getAppState(newState);

      if (bridgeCallback) {
        bridgeCallback(newState);
      }

      return newState;
    });
  });
}

export async function getQuoteData(
  token: any,
  artifactID: string,
  selection: any
) {
  return new Promise((resolve, reject) => {
    if (selection === undefined || selection === null || selection === "") {
      resolve("");
    } else {
      // actually load the history, etc
      let d = `${process.env.NEXT_PUBLIC_LINX_URL}`;
      //d = 'http://localhost:8032'
      axios
        .get(`${d}/quote/byselection`, {
          params: {
            selection: JSON.stringify(selection),
            artifactID: artifactID
          },
          withCredentials: false,
          headers: {
            Authorization: "Bearer " + token
          }
        })
        .then((res) => {
          resolve(res.data);
        });
    }
  });
}

export function getVultrKeyFromArtifactID(artifacts, artifactID, interviews) {
  let match = undefined;

  for (let i = 0; i < interviews?.length; i++) {
    let possibleArtifacts = [];
    if (artifacts?.note && artifacts?.transcript) {
      possibleArtifacts = [
        artifacts?.note[interviews[i]?.interviewID],
        artifacts?.transcript[interviews[i]?.interviewID]
      ];
    }

    for (let c = 0; c < possibleArtifacts?.length; c++) {
      if (possibleArtifacts[c] !== undefined) {
        if (possibleArtifacts[c]?.artifactID === artifactID) {
          match = possibleArtifacts[c]?.vultrKey;
        }
      }
    }
  }

  return match;
}

export function getSelectedInterviewWithDefaultFallback(base, interviewData) {
  let interview = undefined;

  if (base.selectedInterview !== undefined && interviewData !== undefined) {
    for (let i = 0; i < interviewData?.length; i++) {
      if (interviewData[i].interviewID === base.selectedInterview) {
        interview = interviewData[i];
      }
    }
  }

  return interview;
}

export function getCurrentArtifactID(base, artifacts) {
  const interviewID = base.selectedInterview;

  if (interviewID === undefined) {
    return undefined;
  }

  const artifactType = base.selectedArtifactType;

  if (artifactType === "note" || artifactType === "notes") {
      return artifacts?.note?.[interviewID].artifactID;
  }

  if (artifactType === "transcript" || artifactType === "transcription") {
      return artifacts?.transcript?.[interviewID].artifactID;
  }

  // try to return the note in this case anyways, assuming the tab hasn't been clicked
  try {
    return artifacts?.note?.[interviewID].artifactID;
  } catch (e) {
    appLog(e);
    return undefined;
  }
}

export function setTeamCanvasLayoutID(set, layoutID) {
  set((base) => {
    let buffer = {
      ...base
    };

    buffer.team.canvasLayoutID = layoutID;

    return buffer;
  });
}

export function toggleAllFavoritedVisibility(set, base) {
  //  Axios call?
  set((base) => {
    let buffer = {
      ...base
    };

    buffer.favoritedTagsVisibility = !buffer.favoritedTagsVisibility;

    return buffer;
  });
}

export function checkRole(base, roleName) {
  try {
    return base.roleSnapshot[roleName];
  } catch (e) {
    return false;
  }
}

export function toggleOrgTreeItemExpanded(set, context, expanded, hierarchy) {
  set((base) => {
    let buffer = {
      ...base
    };

    if(hierarchy?.length === 0 || !context || !context.groupID){
      renderErrorToast("Can't proceed to open tree");
      console.error("Hierarchy or context data missing");
      return buffer;
    }

    const hierarchyRootID = hierarchy[0]?.parentID;

    // TODO: we may need to close the expansion of other entries when we do this
    if (context?.parentID && context?.parentID !== hierarchyRootID) {
      // read folders on the root level
      for (let folder of hierarchy) {
        // check for all the cohorts in it
        if (folder.group?.groupID === context.parentID) {
          for (let cohort of folder.children) {
            // if it's there expand it
            if (cohort.group.groupID === context.groupID) {
              cohort.isExpanded = expanded;
              break;
            }
          }
          break;
        }
      }
    } else {
      // check for cohorts on the root level
      for (let folder of hierarchy) {
        if (folder.group?.groupID === context.groupID) {
          folder.isExpanded = expanded;
          break;
        }
      }
    }
    return buffer;
  });
}

export const canAccessScoringPage = (base: BASEStateInterface): boolean => {
  const { cohort, members, user } = base;
  const isInstructor = checkRole(base, "isInstructor");
  const isTeamMember = checkRole(base, "isTeamMember");
  const isAMemberOfThisTeam = members.find(
    (member) => member?.userID === user?.userID
  );

  const canAccess =
    isInstructor ||
    (isTeamMember &&
      isAMemberOfThisTeam &&
      (cohort?.canMembersViewPresentations === 1 ||
        cohort?.canMembersViewPresentationFeedback === 1));

  return canAccess;
};

export const downloadExportInterviewSignedURL = (signedUrl) => {
  if (!signedUrl) {
    renderErrorToast("Can't export interview");
    return;
  }
  try{
    const link = document.createElement("a");

    link.href = signedUrl;
    link.download = `team-interview-${new Date().getTime()}.html`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  catch(error){
    renderErrorToast("Error downloading file");
  }
};

export const downloadExportCanvasSignedURL = async (
  signedURL,
  CanvasGroupID
) => {
  if (!signedURL || !CanvasGroupID) {
    renderErrorToast("Can't export canvas");
    return;
  }

  try{
    const res = await fetch(signedURL);
    const canvasFile = await res.blob();
    const blobUrl = URL.createObjectURL(canvasFile);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = `can-exp-${CanvasGroupID}.json`;
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(blobUrl);
  }
  catch(error){
    renderErrorToast("Error downloading file");
  }

};

export const isCanvasEmpty = (canvasData) => {
  const categories = flatten(
    canvasData?.categories?.map((category) => category.entries)
  );
  const categoryInUse = categories?.find(
    (entry: any) => entry.state !== "Deleted"
  );
  return !categoryInUse;
};

export function navigateTo(set: any, request: string) {
  set((_base) => {
    let newBaseState = {
      ..._base
    };
    newBaseState.pathRequest = request;

    return newBaseState;
  });
}


type Count = {
  AI: number;
  Human: number;
  Combine: number;
  hasData: boolean;
}

export function checkEntryInsertionType(canvasData): Count {
  const count = {
    AI: 0,
    Human: 0,
    Combine: 0,
    hasData: true
  };

  canvasData.categories.forEach(category => {
    if (category.entries && Array.isArray(category.entries)) {
      category.entries.forEach(entry => {
        if (entry.entryInsertionType) {
          switch (entry.entryInsertionType) {
            case 'AI':
              count.AI++;
              break;
            case 'Human':
              count.Human++;
              break;
            case 'Combine':
              count.Combine++;
              break;
            default:
              break
          }
        } else {
          return {
            AI: 0,
            Human: 0,
            Combine: 0,
            hasData: false
          }
        }
      });
    }
  });

  if(count.AI === 0 && count.Combine === 0 && count.Human === 0) count.hasData = false;
  return count
}

export const findParent = (childID: string, hierarchyData: Array<any>): any | null => {
  const searchParent = (data: Array<any>, parent: any | null = null): any | null => {
      for (const item of data) {
          if (item.childID === childID) {
              return parent;
          }
          if (item.children && Array.isArray(item.children)) {
              const foundParent = searchParent(item.children, item);
              if (foundParent) return foundParent;
          }
      }
      return null;
  };

  return searchParent(hierarchyData);
}

export const findChild = (childID: string, hierarchyData: Array<any>): any | null => {
  const searchChild = (data: Array<any>): any | null => {
      for (const item of data) {
          if (item.childID === childID) {
              return item;
          }
          if (item.children && Array.isArray(item.children)) {
              const foundChild = searchChild(item.children);
              if (foundChild) return foundChild;
          }
      }
      return null;
  };

  return searchChild(hierarchyData);
};


export const flattenHierarchy = (data) => {
  const flattenedData = [];

  const traverseHierarchy = (item, parentID) => {
      const { group, children } = item;

      // Include only Cohorts and Teams, excluding Folders and "-" names
      if (group.groupType !== "Folder" && group.name !== "-") {
          flattenedData.push({
              groupID: group.groupID,
              name: group.name,
              groupType: group.groupType,
              parentID: parentID || undefined,
          });
      }

      // Recursively traverse children
      if (children) {
          children?.forEach(child => traverseHierarchy(child, group.groupID));
      }
  };

  data?.forEach(item => traverseHierarchy(item,undefined));
  return flattenedData;
};